import React from 'react'
import cn from 'classnames'

const Component = ({
    state: {
        containerSize = 'standard',
        alignContent = 'flex-start',
        matchContentHeight,
        mobileTwoColumnLayout,
    },
    children,
}) => {
    return (
        <div
            className={cn(
                {
                    container: containerSize === 'standard'
                },
                '!px-0',
            )}
        >
            <div
                className={cn({
                    'container__match-height': matchContentHeight,
                    'container__align-content--top': alignContent === 'flex-start',
                    'container__align-content--center': alignContent === 'center',
                    'container__align-content--end': alignContent === 'flex-end',
                    'mobile-two-col-layout': mobileTwoColumnLayout,
                })}
            >
                {children}
            </div>
        </div>
    )
}

export default Component
